<template>
  <div class='city'>
    <header-top ref="headerTop" />
    <div class="content">
      <left-top ref="leftTop" />
      <center-top ref="centerTop"/>
      <right-top ref="rightTop" />
    </div>
    <div class="content">
      <left-bottom ref="leftBottom" />
      <center-bottom />
      <right-bottom />
    </div>
  </div>
</template>

<script>
import HeaderTop from './components/header'
import CenterBottom from './components/centerBottom'
import CenterTop from './components/centerTop'
import LeftBottom from './components/leftBottom'
import LeftTop from './components/leftTop'
import RightBottom from './components/rightBottom'
import RightTop from './components/rightTop'
import { getAreaList,getCountStatistic } from '@/api'
export default {
  // components: { HeaderTop, CenterBottom, CenterTop, RightBottom, RightTop, LeftBottom, LeftTop },
  components: { HeaderTop, LeftTop, RightTop, CenterTop, LeftBottom, CenterBottom, RightBottom },
  data() {
    return {

    };
  },
  created() {
    this.init()
  },
  mounted() {

  },
  methods: {
    // init () {
    //   getEnter().then(res => {
    //     // this.$store.commit("SET_ENTER", res.data)
    //     // console.log('res :>> ', res);
    //     // this.$refs.leftTop.getName(JSON.parse(JSON.stringify(res.data)))
    //     // this.$refs.centerTop.init(JSON.parse(JSON.stringify(res.data)))
    //     // this.$refs.leftBottom.init(res.data.filter(el => el.type === 'asian')[0])
    //   })
    //   // getAsiad().then(res=>{
    //   //   this.$refs.leftBottom.init(res.data.asianGamesList,res.data.readyCount,res.data.totalCount)
    //   //   console.log(res);
    //   // })
    // }
    init() {
      getAreaList().then(res => {
        this.$refs.headerTop.getTit(res.data)
        this.$refs.leftBottom.getcode(res.data)
        this.$refs.centerTop.mapData(res.data)
      })
      getCountStatistic().then(res => {
        this.$refs.centerTop.init(res.data)
        this.$refs.headerTop.getRunTime(res.data)
        this.$refs.rightTop.init(res.data)
      })
    }
  }
};
</script>

<style scoped lang="scss">
.city {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  background: url("../../assets/city/bg.png") no-repeat;
  background-size: 100% 100%;

  .content {
    padding: 0 24px 24px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
