<template>
  <div class="cityheader">
    <div class="left">
      <i :class="`iconfont ${icon}`"></i>
      <div class="weather">
        <p>{{leftName}}</p>
        <p><span>{{temperature}}℃</span> <span>{{weather}}</span></p>
      </div>
      <p class="line"></p>
    </div>
    
    <div class="right">
      <div class="time">
        <div class="img">
          <img src="@/assets/city/time.png" alt="">
          时间
        </div>
        <div class="timeBox">
          <div>{{this.$moment(time).format('YYYY-MM-DD HH:mm:ss')}}</div>
          <div>运行 <span style="color:#00FFEA ;">{{ count.runtime }}</span> 天</div>
        </div>
      </div>
      <div class="goback" @click="goback">
        <i></i>
        <span>返回平台</span>
      </div>
    </div>
    <div class="center">
      智慧酒店保洁平台
    </div>
  </div>
</template>

<script>
var interval
import AMap from 'AMap'
import { mapGetters } from 'vuex'
import { environment } from '@/utils/config.js'
export default {
  data () {
    return {
      time: this.$moment(),
      leftName: '',
      temperature: 0,
      weather: '晴',
      icon: null,
      title: '',
      count:{},
      timer: null,
    };
  },
  watch:{
    title(newVal){
      if (newVal) {
        this.initWeather()
      }
    }
  },
  created () {
    interval = setInterval(() => this.time = new Date(), 1000);
   
  },
  mounted () {
    this.timer=setInterval(() => {this.initWeather()}, 18000000);
  },
  destroyed () {
    clearInterval(interval)
    clearInterval(this.timer)

  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    // 获取天气
    initWeather () {
      // debugger
      const _this = this
      AMap.plugin('AMap.Weather', function () {
        //创建天气查询实例
        var weather = new AMap.Weather();
        //执行实时天气信息查询
        weather.getLive(localStorage.getItem('areaName'), function (err, data) {
          let iconfont = 'icon-tianqiyubao'
          if (!err) {
            if (data.weather.indexOf('阴') !== -1) iconfont = 'icon-yin'
            if (data.weather.indexOf('晴') !== -1) iconfont = 'icon-qing'
            if (data.weather.indexOf('云') !== -1) iconfont = 'icon-duoyun'
            if (data.weather.indexOf('雨') !== -1) iconfont = 'icon-yu'
            if (data.weather.indexOf('雪') !== -1) iconfont = 'icon-zhenxue'
            if (data.weather.indexOf('风') !== -1) iconfont = 'icon-feng'
            if (data.weather.indexOf('霾') !== -1) iconfont = 'icon-mai'
            if (data.weather.indexOf('雾') !== -1) iconfont = 'icon-wu'
            if (data.weather.indexOf('沙') !== -1) iconfont = 'icon-qiangshachenbao'
            document.title = `${data.city}“住宿卫生在线”`
            if (data.city === '温州市') {
              _this.title = '温州市'
            } else {
              _this.title = data.city
            }
            _this.icon = iconfont,
              _this.temperature = data.temperature,
              _this.weather = data.weather,
              _this.leftName = data.city
          }

        });
      });
    },
    goback () {
      // const IP = environment === 'TE' ? 'https://ivatest.terabits.cn' : 'https://zjiva.terabits.cn'
      const IP = environment === 'DE' ? 'https://ivatest.terabits.cn' : 'https://zjiva.terabits.cn'
      // const type = localStorage.getItem('type')
      // if (type) {
      // window.open(`http://ldiva.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // } else {
      window.open(`${IP}/app/hotels`)
      // window.open(`http://ivatest.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // }
    },
    getTit(data){
      const name = data[0].name.split(',')
        if (name[name.length - 1] === '莲都区') {
          localStorage.setItem('type', 'ld')
          localStorage.setItem('hoteltitle', '民宿')
        } else {
          localStorage.setItem('hoteltitle', '酒店')
          localStorage.setItem('type', '')
        }
        this.title=name[name.length - 1]
        localStorage.setItem('areaName',name[name.length - 1])
        localStorage.setItem('areaCode', data[0].adcode)
    },
    getRunTime(val){
      this.count = val
    }
  }
};
</script>

<style scoped lang="scss">
.cityheader {
  // width: 100%;
  height: 80px;
  background: url("../../../../assets/city/header.png") no-repeat center bottom;
  background-size: 100% 100%;

  display: flex;
  justify-content: space-between;
  padding: 16px 24px 0 23px;
  margin-bottom: 3px;
  position: relative;
  .center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 36px;
    color: transparent;
    background-image: linear-gradient(135deg, #95fcf3, #7088c9, #fff);
    background-clip: text;
    font-weight: bold;
    background-image: -webkit-linear-gradient(135deg, #95fcf3, #7088c9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .left {
    display: flex;
    font-size: 16px;
    color: #ffffff;
    .iconfont {
      font-size: 24px;
      color: white;
      margin-right: 12px;
    }

    .weather {
      p{
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
      }
      p:nth-child(2) {
        font-size: 12px;
        margin-top: 10px;
        span {
          margin-right: 17px;
        }
      }
    }
    .line {
      width: 1px;
      height: 27px;
      background: #0e6698;
      margin: 0 19px 0 3px;
    }
  }

  .right {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #29ddfe;
    .time{
      display: flex;
      // align-items: center;
      .img{
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #00FFEA;
        img{
        width: 28px;
        height: 27px;
        margin-right: 10px;
        }
      }
      .timeBox{
        color: #fff;
        div{
          margin-left: 15px;
          &:first-child{
            margin-bottom: 10px;
          }
        }

      }
      


    }

    .goback {
      margin-left: 85px;
      background: url("../../../../assets/city/back_bg.png") no-repeat;
      background-size: 100% 100%;
      width: 92px;
      height: 41px;
      line-height: 41px;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        display: block;
        background: url("../../../../assets/city/back_icon.png") no-repeat ;
        background-size: 100% 100%;
        width: 9px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }
}
</style>
